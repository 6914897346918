<template>
  <v-card
    :loading="loader.detail"
    class="pb-16"
  >
    <v-row>
      <v-col
        lg="4"
        md="4"
        sm="12"
      >
        <v-img
          :max-height="slider_height"
          :src="comicbook.banner"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0 "
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col
        lg="8"
        md="8"
        sm="12"
      >
        <v-card-title color="deep-purple darken-3">
          {{ comicbook.name }}
          ({{ comicbook.alternatif }})
        </v-card-title>
        <v-card-subtitle color="deep-purple darken-3">
          Auteur(s) :
          <template v-for="author in comicbook.authors">
            {{ author.pseudo }}
          </template>
        </v-card-subtitle>

        <v-card-text>
          Genres :
          <v-chip
            v-for="gender in comicbook.genders"
            :key="gender.id"
          >
            {{ gender.name }}
          </v-chip>
        </v-card-text>

        <v-card-title color="deep-purple darken-3">
          3 derniers Chapitres  
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <template v-for="(item, index) in chapters">
              <v-divider
                :key="index"
              ></v-divider>

              <v-list-item
                :key="item.title"
                :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                dense
              >
                <img
                  class="mr-3"
                  width="50"
                  :src="item.cover_src_sm"
                >

                <v-list-item-content>
                  <v-list-item-title class="title-inline">
                    <b>#{{ item.number }}</b> {{ item.title }}
                    <v-spacer></v-spacer>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.creationdate }} - <v-icon small>
                      {{ icons.mdiEyeOutline }}
                    </v-icon> {{ item.nbviews }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                </v-list-item-icon>
                <v-list-item-action>
                  <v-chip
                    v-if="item.product.price == 0"
                    small
                    color="success"
                  >
                    Free
                  </v-chip>
                  <template v-if="item.product.price > 0">
                    <v-chip
                      small
                      color="warning"
                    >
                      {{ item.product.price }} Credits
                    </v-chip>
                    Extrait {{ item.free_pages }} pages
                  </template>
                  <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        
        <v-card-subtitle color="deep-purple darken-3">Synopsis</v-card-subtitle>
        <v-card-text>
          {{ comicbook.synopsis }}
        </v-card-text>

        <v-card-title color="deep-purple darken-3">
          Historique des Chapitres  
        </v-card-title>
        <v-card-subtitle color="deep-purple darken-3">
          Chapitres de : 
          <v-chip
            v-for="page in ll.pagination"
            :key="page"
            :color="nextpage == page?'primary': ''"
            @click="init(page)"
          >
            {{ ((page - 1)*ll.per_page)+1 }} - {{ page*ll.per_page }}
          </v-chip>
        </v-card-subtitle>
        <v-card-text>
          <v-list dense>
            <template v-for="(item, index) in ll.listEntity">
              <v-divider
                :key="index"
              ></v-divider>

              <v-list-item
                :key="item.title"
                :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                dense
              >
                <img
                  class="mr-3"
                  width="50"
                  :src="item.cover_src_sm"
                >

                <v-list-item-content>
                  <v-list-item-title class="title-inline">
                    <b>#{{ item.number }}</b> {{ item.title }}
                    <v-spacer></v-spacer>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.creationdate }} - <v-icon small>
                      {{ icons.mdiEyeOutline }}
                    </v-icon> {{ item.nbviews }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                </v-list-item-icon>
                <v-list-item-action>
                  <v-chip
                    v-if="item.product.price == 0"
                    small
                    color="success"
                  >
                    Free
                  </v-chip>
                  <template v-if="item.product.price > 0">
                    <v-chip
                      small
                      color="warning"
                    >
                      {{ item.product.price }} Credits
                    </v-chip>
                    Extrait {{ item.free_pages }} pages
                  </template>
                  <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import { useRouter } from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'

export default {
  components: { ToggleFavoriteComponent },
  setup() {
    const $vuetify = getVuetify()

    const { router } = useRouter()
    const route = router.currentRoute
    const { id } = route.params
    const comicbook = ref({})
    const ll = ref({per_page : 9})
    const loader = ref({
      detail: true,
    })
    const nextpage = ref(1)
    const chapters = ref([])

    Drequest.api(`detail.comicbook?id=${id}`)
      .get(response => {
        console.log(response)
        loader.value.detail = false
        comicbook.value = response.comicbook
      })

    let url = ''
    const user_id = localStorage.getItem('user_id')
    if (user_id) {
      url = `&user_id=${user_id}`
    }
    const sub_id = localStorage.getItem('subscription_id')
    if (sub_id) {
      url += `&subscription_id=${sub_id}`
    }

    const init = (page) => {
      nextpage.value = page

      loader.value.detail = true
      Drequest.api(`chapter.lazyloading?dfilters=on&next=${page}&dsort=number asc&per_page=9&status:eq=1&comicbook_id:eq=${id}${url}`)
      //.param({next: page})
      .get(response => {
        console.log(response)
        loader.value.detail = false
        ll.value = response 
      })

    }
    init(1)

    Drequest.api(`chapter.lazyloading?dfilters=on&next=1&dsort=id desc&per_page=3&status:eq=1&comicbook_id:eq=${id}${url}`)
      //.param({next: page})
      .get(response => {
        console.log(response) 
        chapters.value = response.listEntity
      })

    const slider_height = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 300
      }
      if ($vuetify.breakpoint.xs) {
        return 200
      }

      return 800
    })

    return {
      init,
      ll,
      loader,
      comicbook,
      chapters,
      slider_height,
      nextpage,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
